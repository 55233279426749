body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  max-width: 100%;
}

input,
select,
textarea {
  font-size: 16px;
}

div.show-page {
  position: relative;
  overflow: auto;
  max-width: 100%;
  box-sizing: border-box;
}

div.show-page div.ra-field.ra-field-break {
  display: block;
  width: 99%;
  height: 0;
  border-bottom: 1px dotted #ccc;
}

div.show-page div.ra-field.ra-field-show_title {
  display: block;
  width: 100%;
}

div.filter-field div.marketplace-provider-filter {
  width: 185px;
}

div.filter-field div.marketplace-provider-filter {
  width: 185px;
}

div.money-site-show div.ra-field-website_directories label,
div.money-site-show div.ra-field-missing_website_directories label,
div.money-site-show div.ra-field-link_sites label,
div.money-site-show div.ra-field-missing_link_sites label {
  font-weight: bold;
  font-size: 22px;
}

div.money-site-show div.ra-field-link_sites,
div.money-site-show div.ra-field-missing_link_sites,
div.money-site-show div.ra-field-website_directories,
div.money-site-show div.ra-field-missing_website_directories {
  display: inline-block;
  margin: 2px 5px;
  max-width: 48%;
}

div.ra-field-button {
  display: inline-block;
  margin-right: 20px;
}

div.input_error input,
div.input_error div.rdw-editor-wrapper {
  border-bottom: 2px solid red;
}

div.error_message {
  color: red;
  font-style: italic;
}

div.message {
  font-weight: bold;
}

tr.disabled {
  background-color: #d2d2d2;
}

.fullwidth {
  margin-left: -24px;
  margin-right: -24px;
}

del {
  background-color: #ffa19b;
}

ins {
  background-color: #a4eaa4;
}

div[role*="tooltip"] {
  z-index: 99999999999;
}

.makeStyles-parentWrapperActive-39 {
  background: #1e1e1e !important;
  border-left: 2px solid #b71c1c !important;
}
